// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap
	body footer{ 
		margin-top: $gutter*4;
		background-color: $background;

		.footerbar {
			height: $offset-vertical*2;

			.copyright {
				margin-bottom: 15px;
				font-size: 12px;
			}
			.design{
				letter-spacing: 2px;

				a {
					font-size: .7em;
					color: $light-grey;

					&:hover {
						color: #c6c889;
					}

					img{
						width: 20px;
						height: 17px;
					}
				}

			}
		}

		/* SOCIAL LINKS */
		.social-box {

			.social-link {

				img {
					height: 30px;
					width: 30px;
				}
			}
		}
	}


@media (max-width: 991px) {
	body footer { 

		.footerbar {
			text-align: center;

			.copyright {
				padding-top: 0px;
				margin-right: auto;
				margin-left: auto;
			}
			.design{
				margin-right: auto;
				margin-left: auto;
			}
		}

		/* SOCIAL LINKS */
		.social-box {
			margin: $gutter;

			.social-link {
				margin: $gutter 4px;
			}
		}
	}
}


// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	body footer{
		padding-top: $offset-vertical*.5;

		.footerbar {
			height: $offset-vertical;
			text-align: left;


			.copyright {
				display: block;
				position: relative;
				float: left;
				clear: both;
				padding-top: 0px;
				margin: -20px 0px $gutter 0px;
				font-size: 12px;
		}
			.design{
					display: block;
					position: relative;
					float: left;
					clear: both;
				}

		/* SOCIAL LINKS */
		.social-box {
			display: block;
			position: relative;
			float: right;
			clear

			.social-link {
				display: block;
				float: right;
				margin: 0px 4px;

				img {
					height: 30px;
					width: 30px;
				}
			}
		}

	}
}
}

// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }