// Grid settings
$main-lg-columns:       12;
$sidebar-lg-columns:    3;

// additional breakpoints
$screen-xxs-max: 539px;
$screen-xs-min: 540px;
$screen-sm-min: 576px;
$grid-float-breakpoint: 992px;
$mobile-nav-breakpoint: 991px;

// Margins
$offset-vertical:		80px;
$gutter:				25px;
$body-gutter:			90px;
$row-margin-vertical:	150px;

// Colors
$brand-primary:         #64dd17;
$brand-dark:			#32cb00;
$brand-light:			#b0ff57;
$a-brand:				#64dd17;
$card-bkg:				#f9fbe7;
$card-overlay:			rgba(249, 251, 231, .95);
$background:			#fcfcfa;

$dark-grey:				#424242;
$grey:					#8a8c7c;
$light-grey:			#c6c8b5;

$dark-green:			#524c00;

$shadows:				#888888;


$sidenav-bg: #DFDFDF;
$sidenav-link: #484848;
$sidenav-child-link: #797979;
$sidenav-link-hover: darken($sidenav-link, 5%);
$sidenav-link-hover-border: darken($sidenav-bg, 15%);
$sidenav-link-active: $brand-primary;

$pattern-row-border: $dark-grey;

// transitions
$timing: .3s;