/* HEADER STYLES */

// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

body #surround header.navbar {
	padding-top: 0px;
}
	header.banner {
		
		.headerbar {
			position: fixed;
			width:100%;
			height: 90px;
			margin-top: 0px;
			box-shadow: 2px 2px 5px $shadows;
			padding-top: 28px;
			padding-right: 10px;
			padding-left: 10px;
			top: 0px;
			z-index: 2;
			background-color: rgba(255, 255, 255, 1);
			text-transform: none;

			a.brand{
				display: inline;
				margin-top: 0px;
				margin-right: 0px;
				margin-left: 0px;
				/*font-size: 1.75em;*/
				font-size: 1em;
				letter-spacing: 1px;
				color: $dark-grey;
			}
		}

		.banner-image-background {
			margin-top: $offset-vertical;
			border-radius: 0px;
			height: 300px;
			width: 100%;

			.header-overlay {
				min-height: 400px;
				height: 100%;
				width: 100%;
				background-color: rgba(0, 0, 0, .0);
				color: #ffffff;
			}
		}
		.tagline{
					padding-top: 8%;
					margin-left: 10px;
					margin-right: 10px;
					text-align: center;
					font-size: 1.2em;
					font-weight: 400;
					font-style: italic;
				}

				.home-banner-blurb{
					margin-top: 1em;
					margin-left: 10px;
					margin-right: 10px;
					text-align: center;
					
				}
				.heal-now-button {
					text-align: center;

					.button-text {
						padding: 10px;
						
						border-radius: 4px;
						background-color: $brand-primary;
						color: $dark-grey;
						letter-spacing: 2px;

						&:hover {
							background-color: $brand-dark;
							color: #000000;
							transition-duration: $timing;
						}
					}
				}
		.header-card {
			padding: $gutter;

			.card-header {
				padding-bottom: 0px;
				background-color: transparent;
				border-style: none;
				text-align: center;

				.card-header-tagline {
					margin-top: 0;
					margin-bottom: 15px;
					font-size: 1.5em;
					font-style: italic;
					line-height: 1em;
				}
			}
			.card-text {
				padding: 0 $gutter;

				.home-banner-blurb {
					margin-top: 0;
					margin-bottom: $gutter;
				}

				.button-text{
					margin-top: 5px;
					margin-bottom: 15px;
					box-shadow: 2px 2px 5px $shadows;
				}
			}
			
		}
	}

header.pages-header {
	height: $offset-vertical+$gutter;
}
.modal-content {
	border: none;
	/*background-color: transparent;*/
}
.modal-backdrop.show {
	opacity: .85;
}
/*.modal-dialog {
	max-width: 100%;
	width: 50%;
}
.modal-header{
	border: none;
}

.modal{
	iframe{
		display: block;
		margin-right: auto;
		margin-left: auto;
	}
}*/

button.close span{
	color: $brand-primary;
	font-size: 2em;
	padding-right: $gutter;
}
.heal-now-button button{
	margin-top: 5px;
	margin-bottom: 15px;
	box-shadow: 2px 2px 5px $shadows;
	padding: 10px;
    border-radius: 4px;
    background-color: $brand-primary;
    color: $dark-grey;
    letter-spacing: 2px;

    	&:hover {
			background-color: $brand-dark;
			color: #000000;
			transition-duration: $timing;
		}
}

.play-icon{
	display: inline-block;
	height: 12px;
	width: 12px;
	margin-top: 3px;
	margin-right: 4px;
	background-color: $dark-grey;
	-webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 50%);
	clip-path: polygon(0% 0%, 0% 100%, 100% 50%);
}

    

// hover behavior for dropdowns
li.dropdown {
	ul.dropdown-menu {
		margin: 0;
		border-radius: 0px;
	}
	
    &:hover,
    &:focus {
        ul {
            display: block;
            transition-duration: $timing;
        }
    }

    a.dropdown-toggle::after {
			display: none;

	}
}
@media (max-width: 575px) {
}
@media (max-width: 991px) {

	header.banner {

		nav.nav-primary {
			display: none;
		}
	}	
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
	.header-banner{
		.header-card{
			.card-header{
				padding-right: 1.25em;
				padding-left: 1.25em;
			}
		}
	}	

 }

@media (max-width: 767px) {
	body #surround header.navbar {
		padding: 0px;
	}
	.header-copy {
		display: none;
	}
		.modal-dialog { 
	    position: relative; 
	    padding-bottom: 56.25%;
	    height: 0;
	    overflow: hidden;
	    max-width: 100%;
	    height: auto;
	    } 

	  .modal-dialog iframe,
	  .modal-dialog object,
	  .modal-dialog embed { 
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 100%;
	    height: 100%;
	    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
	header.banner {
		padding: 0px 0px;

		.headerbar {
			margin-left: 0px;
			padding-right: 50px;
			padding-left: 50px;
			
			a.brand {
				/*font-size: 1.75em;*/
				font-size: 1em;
				font-weight: 400;
				text-align: left;
			}
		}

		.banner-image-background {
			border-radius: 0px;
			height: 500px;
			width: 100%;

			.header-overlay{
				border-radius: 0px;
				/*background: -webkit-linear-gradient(right,rgba(40,81,1,.2),rgba(0,0,0,.2));*/
			}
		}
		.header-copy {
			.tagline{
				padding-top: 5%;
				margin-left: 50px;
				font-size: 2.5em;
				text-align: left;
			}

			.home-banner-blurb{
				width: 60%;
				margin-left: 50px;
				text-align: left;
				font-size: 22px;
				line-height: 1.25;
			}

			.heal-now-button {
				text-align: left;
				margin-left: 50px;
				box-shadow: none;
			}
		}
		.header-copy-mobile {
			display: none;
		}

		.header-card {
			margin-top: -125px;

			.card-header {

				.card-header-tagline {
					font-size: 1.7em;
				}
			}
		}
	}
	.modal-dialog {
		max-width: 640px;
	}
	button.small-hide {
	    	display: none;
	}
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {

	header.banner {

		.headerbar {
			
			.navbar-header {
				
				button {
					display: none;
				}
			}
		}
		nav.nav-primary {
			display: block;
			position: relative;
			/*padding-top: 10px;*/
			padding-top: 7px;
			float: right;
			font-size: 16px;

			li {
				margin-left: .5*$gutter;
				padding: 2px;


				a {
					color: $dark-grey;
				}

				a:hover {
					color: $brand-dark;
				}

				a:active {
					color: $brand-primary;
					text-decoration: none;
				}
			}

			ul.dropdown-menu {
				margin-left: -10px;
				border-style: none;
				background-color: rgba(255, 255, 255, .95);
				font-size: inherit;

				li {
					padding: 10px;
					margin: 2px 0px;
				}

				li:hover {
					background-color: rgba(255, 255, 255, 1);
				}
			}
		}
	}

	.modal-dialog{
		width: 75%;
	}
	
}

// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }



