body {
    position: relative;
}

@media screen and (max-width: $mobile-nav-breakpoint) {
    body.sidenav-active {
        #nav-offcanvas {
            overflow: visible;

        }

        #surround {
            margin: 0px;
            -webkit-transform: translate3d(-70%, 0, 0);
            -moz-transform: translate3d(-70%, 0, 0);
            -ms-transform: translate3d(-70%, 0, 0);
            -o-transform: translate3d(-70%, 0, 0);
            transform: translate3d(-70%, 0, 0);

            #toggle {
                opacity: 0;
            }
        }
    }
}

#surround {
    background-color: $background;
    right: 0;
    overflow: hidden;
    position: relative;
    z-index: 1;
    /*-webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);*/
    -webkit-transition: -webkit-transform .5s ease;
    -moz-transition: -moz-transform .5s ease;
    -o-transition: -o-transform .5s ease;
    transition: transform .5s ease;
}

#nav-offcanvas {
    background-color: transparent;
    bottom: 0;
    font-weight: 500;
    font-size: 14px;
    left: 30%;
    padding: $offset-vertical $gutter;
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 0;
    z-index: 0;
    position: fixed;

    ul {
        margin: -1px 0 0;
        padding: 0;


        &.sub-menu {
            margin-top: -1rem;
            margin-left: $gutter;
            padding-bottom: 2rem;


            li {
                /*padding-left: 2rem;*/


                a {
                    border-top-color: transparent !important;
                    color: $sidenav-child-link;
                    padding: 10px;

                    &:hover,
                    &:focus {
                        color: $brand-primary;
                    }
                }
            }
        }
    }

    li {
        display: block;
        text-transform: uppercase;

        &.active {
            &>a {
                border-left: 5px solid $sidenav-link-active !important;
                color: $sidenav-link-active !important;
                opacity: 1 !important;
            }
        }

        a {
            border-left: 5px solid transparent;
            color: $sidenav-link;
            display: block;
            margin-left: -$gutter;
            padding: 10px;
            text-decoration: none;
            transition: all 0.3s ease;

            &:hover,
            &:focus {
                background-color: transparent;
                border-left: 5px solid $sidenav-link-hover-border;
                color: $brand-primary;
            }
        }
    }
}

.navbar-default {
    .navbar-toggle {
        background-color: transparent;
        border: 0;
        border-radius: 0;
        margin-top: 28px;
        margin-right: 30px;
        padding: 0px;
        position: fixed;
        right: 0;
        top: 0;
        transition: all $timing ease;
        z-index: 999;

        &:focus {
            background-color: $background;
        }

        &:hover {

            .nav-label {
                color: #fff;
            }
            .icon-bar {
                background-color: $brand-primary;
            }
        }

        .nav-label {
            // color: $bf-grey;
            font-weight: 700;
            margin-right: 7px;
        }
        .nav-label,
        .bars {
            display: inline-block;
        }
        .bars {
            margin-bottom: -1px;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .navbar-default {
        .navbar-toggle {
            margin-right: 50px;
        }
    }  
}

.sidenav-active {
    .navbar-default {
        .navbar-toggle {
            background-color: #fff;
            position-fixed

            .nav-label {
                color: #fff;
            }
            .icon-bar {
                background-color: $brand-primary;
            }
        }
    }
}

#toggle-close {
    &:hover {
        .legend {
            // color: $bf-white;
        }
        .icon-bar {
            // background-color: $bf-white;
        }
    }
}

#nav-offcanvas {
    .navbar-toggle {
        background-color: transparent;
        border-style: none;
        font-size: 40px;
        color: $dark-grey;
        display: block;
        position: fixed;
        right: 30px;
        top: 5px;

        &:hover {
            color: $brand-primary;
        }
    }
}

@media screen and (min-width: $screen-sm-min) {
    #toggle {
        margin-left: -2rem;
    }
}

@media screen and (min-width: $grid-float-breakpoint -1) {

}
