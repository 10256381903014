// Extra small devices (portrait phones, less than 576px)

/* MAIN STYLES */
body {
	background-color: $background;
	font-family: "Open Sans", sans-serif;
	color: $dark-grey;
	font-size: 18px;
}

.col-centered {
	float: none;
	margin: 0 auto;
}

.image-box {
	
	img {
		width:100%;
		height: auto;
	}
}

.body-row {
	margin: 0px 0px;
	padding: $gutter 0px;
	}
	
.col-centered{
    float: none;
    margin: 0 auto;
}
/* HEADINGS */
.brand{
	font-family: "Spinnaker", sans-serif;
	text-transform: lowercase;
}
h3 {
	margin-bottom: $gutter;
	margin-top: $gutter;
	font-size: 30px;
}


/* ALTERNATIVE CARD STYLES */
.alt-card {
	height: auto;
	width: 100%;
	border-radius: 0px;
	border-style: none;
	margin-top: $gutter*2;
	background-color: $card-bkg;
	text-align: left;
	background-color: $background;
	
	h2 {
		font-size: 22px;
	}

	p {
		margin-top: .5*$gutter;
		font-style: italic;
	}
	
	/* ABOUT MENU NAVIGATION */
	.about-menu-navigation {
		width: 99%;
		margin-top: $gutter;
		margin-right: auto;
		margin-left: auto;
		text-transform: uppercase;
		text-align: center;
		font-size: 12px;
		li {
			padding: 0px $gutter;
			border-right: 1px solid $dark-grey;


			a {
				color: $dark-grey;
			}

			a:hover {
				color: $brand-primary;
				transition-duration: $timing;
			}

			a:active {
				color: $brand-primary;
				text-decoration: none;
			}
		}
		li:last-child {
			border-right: none;
		}
	}
}

/* LINKS */
a:active {
	color: $a-brand;
}
a {
	text-decoration: none;
	color: $a-brand;
}
a:hover {
	color: $brand-dark;
	text-decoration: none;
	transition-duration: $timing;
}




// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
	body main .alt-card {
		ul.about-menu-navigation {
			li {
				font-size: 14px;
			}	
		}
	}
}
// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
	div {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.content {
	margin: 0px 35px;
	}
}

// Large devices (desktops, 992px and up)
//@media (min-width: 992px) {...}

// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }