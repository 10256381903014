// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap


.main {
	margin-top: $gutter;

	.row {
		margin-top: $gutter;
		margin-right: -15px;
		margin-left: -15px;
	}
}

/* EMPHASIS BLOCK */
.emphasis-rule {
	margin-top: $gutter;
	margin-bottom: $gutter;
	border-width: 1px;
	border-color: $dark-grey;
}
.emphasis-box {
	text-align: center;
	font-style: italic;
	margin-left: auto;
	margin-right: auto;
}

h3.vision {
	text-align: center;
}

/* IMAGE BLOCK WITH TEXT OVERLAY */
.overlay-image {
	min-height: 500px;
}
.text-overlay {
	min-height: 400px;
	margin: 5% 1%;
	padding: 2% 4%;
	background-color: $card-overlay;

	h3 {
		margin-top: $gutter;
		margin-bottom: $gutter;

	}
}

/* BIO PAGE */
.bio-page {
	margin-top: $row-margin-vertical;
		img {
			width: 100%;
			height: auto;
		}
}

/* CALL TO ACTION */
.call-to-action-box {
	width: 100%;
	text-align: center;
	margin: $row-margin-vertical 0px;

	h3 {
		width: 100%;
		margin: $gutter;
		padding: 3px;
		text-align: center;
	}


	div.icon-buttons {
		margin-top: $gutter;
		margin-right: auto;
		margin-left: auto;

		a {
			margin: $gutter;

			img {
				height: auto;
				width: 100%;
				max-width: 200px;
			}
		}

		.icon-img {
			padding: $gutter 0px;
		}

	}
	
}

/* RESOURCES SECTION */

.resource-group {
	padding: $gutter 0px;
	margin-right: auto;
	margin-left: auto; 

	h2 {
		margin-bottom: 15px;
	}
}


// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
	.overlay-image {
		min-height: 500px;
	}
	.text-overlay {
		min-height: 400px;
		margin: $gutter*2;
		padding: $gutter $gutter*2;
		background-color: $card-overlay;

		h3 {
			margin-top: $gutter;
			margin-bottom: $gutter;

		}
	}

}

// Medium devices (tablets, 768px and up)
//@media (min-width: 768px) { }

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	.icon-buttons {

		.icon-img {
			margin-right: auto;
			margin-left: auto;
		}
	}
}

// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }