// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap

aside.sidebar {
	height: default;
	margin-top: $gutter;
	padding: 20px $gutter;
	background-color: $card-bkg;

	section {
		h3 {
			color: $grey;
		}
		ul {
			list-style: none;
			padding-left: 20px;

			li a {
				color: $dark-green;

				&:hover {
					color: $brand-primary;
				}
			}
		}
	}
}

@media (max-width: 991px) {
	aside.sidebar {
		section.widget {
			display: block;
			float: left;
			margin-right: $gutter;
		}
	}
}

// Small devices (landscape phones, 576px and up)
//@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
//@media (min-width: 768px) { ... }

// Large devices (desktops, 992px and up)
//@media (min-width: 992px) { ... }

// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }