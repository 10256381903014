// Extra small devices (portrait phones, less than 576px)
// No media query since this is the default in Bootstrap
.excerpts {
	padding-bottom: 35px;
	border-bottom: 1px solid $dark-grey;
}

nav.navigation {
	margin-top: $gutter;

	.nav-links a {
		text-transform: uppercase;
		padding: 5px 10px;
		color: $light-grey;
		border-top: 1px solid $light-grey;
		border-right: 1px solid $light-grey;
		border-bottom: 1px solid $light-grey;
		border-left: 1px solid $light-grey;
	}

	.nav-links div{
		margin: 2px, 0px;
	}
}


/* EXCERT CARDS ON HOMEPAGE*/
.card-box {
	margin-bottom: 30px;
}


.card {
	flex: 1;
	box-shadow: 1px 1px 5px #c6c8b5;

	img {
		width: 100%;
		height: auto;
	}

	.category-tag ul {
		display: block;
		background-color: transparent;
		margin-top: -10px;
		margin-left: 10px;
		padding-left: 0px;
		font-size: 14px;
		z-index: 2;

		li {
			background-color: $brand-primary;
			margin-top: -$gutter*2;
			border-radius: 2px;
			box-shadow: 2px 2px 5px $shadows;
			padding: 2px 10px 2px 10px;
			display: inline-block;
			z-index: 2;

			&:hover {
				color: #000000;
				background-color: $brand-dark;
				transition-duration: $timing;
				}

			a {
				margin-top: -$gutter*2;
				color: $dark-grey;
				letter-spacing: 1px;
				z-index: 2;
			}
		}
	}
}

article {
	padding-top: -$gutter;

	.card-header {
		background-color: #ffffff;
		border-style: none;
		padding: 0rem 1.25rem 0rem 1.25rem;

		.card-title {
			font-weight: 400;
			line-height: .9;
		}
	}

	h2.entry-title a {
		color: $dark-grey;

		&:hover {
			color: $brand-dark;
			transition-timing: $timing;
		}
	}

	

	.card-text {
		padding: .75rem 1.25rem;
		padding-top: 0rem;
		font-size: 16px;
	}

	p {
		margin: $gutter*.5 0px;

		&.byline {
			color: $grey;

			a {
				color: $grey;

				&:hover {
					color: $dark-grey;
				}
			}	
		}
	}
}

//POST SINGLE PAGES
main {
	article {
		header {
			.category-tag {
				ul.post-categories {
					padding: 0px;
					list-style: none;
					text-transform: uppercase;
					font-size: 12px;
					

					li {
						background-color: $brand-primary;
						margin-top: -$gutter*2;
						border-radius: 2px;
						box-shadow: 2px 2px 5px $shadows;
						padding: 2px 10px 2px 10px;
						display: inline-block;
						z-index: 2;

						&:hover {
							background-color: $brand-dark;
							transition-duration: $timing;
						}

						a {
							color: $dark-grey;
							letter-spacing: 1px;	
						}
					}
				}
			}
			.single-page-title {
				margin-bottom: $gutter;
			}
			.img-scale {
				height: 100%;
				width: 100%;
}
		}
	}
}

// Small devices (landscape phones, 576px and up)
//@media (min-width: 576px) { ... }

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
	article {
		.card-header {
			.category-tag {
				ul.post-categories {
					font-size: 12px;
				}
			}

			.byline {
				font-size: 12px;
			}
		}
		h2.entry-title a {
			font-size: 1.3rem;
		}
	}	
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
	main {
		article {
			header {
				.img-scale {
					height: 100%;
					width: 100%;
				}
			}
		}
	}	
}

// Extra large devices (large desktops, 1200px and up)
//@media (min-width: 1200px) { ... }